<template>
  <div
    class="v-application v-application--is-ltr theme--light menuable__content__active"
  >
    <!--begin:: Add Employee-->
    <div class="row gutter-b">
      <div class="col-md-6">
        <b-card header-tag="header" footer-tag="footer">
          <template v-slot:header>
            <h6 class="mb-0">Profile Employee</h6>
          </template>
          <div class="row">
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">NIP <span class="text-danger">*</span></h6>
              <b-form-input
                v-model="employee.nip"
                placeholder="Masukan NIP anda"
                required
              ></b-form-input>
              <div class="text-danger" v-if="$v.employee.nip.$error">
                Tolong isi nip
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">Nama <span class="text-danger">*</span></h6>
              <b-form-input
                v-model="employee.name"
                placeholder="Masukan nama anda"
              ></b-form-input>
              <div class="text-danger" v-if="$v.employee.name.$error">
                Tolong isi nama
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">Email <span class="text-danger">*</span></h6>
              <b-form-input
                v-model="employee.email"
                placeholder="Masukan email anda"
              ></b-form-input>
              <div class="text-danger" v-if="$v.employee.email.$error">
                Tolong isi email
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">
                No Telepon <span class="text-danger">*</span>
              </h6>
              <b-form-input
                v-model="employee.phone"
                placeholder="Masukan nomer telepon anda"
              ></b-form-input>
              <div class="text-danger" v-if="$v.employee.phone.$error">
                Tolong isi no telepon
              </div>
            </div>
            <div class="col-md-6" style="margin-bottom: 35px">
              <h6 class="mb-1">Jenis Kelamin</h6>
              <div class="d-flex" style="gap: 8px">
                <b-form-radio v-model="employee.gender" name="jk" value="L">
                  Pria
                </b-form-radio>
                <b-form-radio v-model="employee.gender" name="jk" value="P">
                  Wanita
                </b-form-radio>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">Tanggal Lahir</h6>
              <b-form-input
                type="date"
                v-model="employee.dob"
                placeholder="Masukan tanggal lahir anda"
              ></b-form-input>
            </div>
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">Tempat Lahir</h6>
              <b-form-input
                v-model="employee.birth_place"
                placeholder="Masukan tempat lahir anda"
              ></b-form-input>
            </div>
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">Golongan Darah</h6>
              <b-form-select
                v-model="employee.blood_type"
                :options="optionBloodType"
              ></b-form-select>
            </div>
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">No E-KTP</h6>
              <b-form-input
                v-model="employee.id_number"
                placeholder="Masukan no E-KTP anda"
              ></b-form-input>
            </div>
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">No KK</h6>
              <b-form-input
                v-model="employee.family_card_number"
                placeholder="Masukan no KK anda"
              ></b-form-input>
            </div>
            <!-- <div class="col-md-6 mb-4">
              <h6 class="mb-1">Nama Bank</h6>
              <b-form-select
                v-model="employee.nameBank"
                :options="optionBank"
              ></b-form-select>
            </div> -->
            <!-- <div class="col-md-6 mb-4">
              <h6 class="mb-1">No Rekening</h6>
              <b-form-input
                v-model="employee.noRek"
                placeholder="Masukan nomer rekening anda"
              ></b-form-input>
            </div> -->
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">No NPWP</h6>
              <b-form-input
                v-model="employee.npwp_number"
                placeholder="Masukan no NPWP anda"
              ></b-form-input>
            </div>
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">Agama</h6>
              <b-form-select
                v-model="employee.religion"
                :options="optionReligion"
              ></b-form-select>
            </div>
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">Alamat</h6>
              <b-form-input
                v-model="employee.address"
                placeholder="Masukan alamat anda"
              ></b-form-input>
            </div>
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">Status Perkawinan</h6>
              <b-form-input
                v-model="employee.marital_status"
                placeholder="Masukan status perkawinan anda"
              ></b-form-input>
            </div>
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">Suku</h6>
              <b-form-input
                v-model="employee.ethnic"
                placeholder="Masukan suku anda"
              ></b-form-input>
            </div>
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">Pendidikan Terakhir</h6>
              <b-form-input
                v-model="employee.education"
                placeholder="Pendidikan terakhir"
              ></b-form-input>
            </div>
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">Bank</h6>
              <b-form-select
                v-model="employee.bank_account.bank.id"
                :options="optionBank"
                value-field="id"
                text-field="name"
              ></b-form-select>
            </div>
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">Nama Rekening</h6>
              <b-form-input
                v-model="employee.bank_account.account_name"
                placeholder="Nama Rekening"
              ></b-form-input>
            </div>
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">No Rekening</h6>
              <b-form-input
                v-model="employee.bank_account.account_number"
                placeholder="No Rekening"
              ></b-form-input>
            </div>
            <div class="col-md-6 mb-4"></div>
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">Foto</h6>
              <b-form-file
                placeholder="Choose a file..."
                id="choose-foto"
                v-model="employee.photo"
                @change="handlePhoto"
                class="mb-2"
                accept="image/*"
              ></b-form-file>
            </div>
            <div class="col-md-6 mb-4" v-if="previewUrl">
              <h6 class="mb-1">Preview</h6>
              <div class="flex">
                <img
                  :src="previewUrl"
                  width="100px"
                  class="mr-2"
                  style="border-radius: 8px"
                />
                <b-button
                  variant="danger"
                  @click="
                    employee.photo = null;
                    previewUrl = null;
                  "
                  >Delete</b-button
                >
              </div>
            </div>
          </div>
        </b-card>
      </div>
      <div class="col-md-6">
        <b-card header-tag="header" footer-tag="footer" class="gutter-b">
          <template v-slot:header>
            <h6 class="mb-0">Assignment</h6>
          </template>
          <div class="row">
            <div class="col-md-12">
              <div class="mb-4">
                <h6 class="mb-1">
                  Site Group (Customer) <span class="text-danger">*</span>
                </h6>
                <b-form-select
                  v-model="selectCustomer"
                  value-field="id"
                  text-field="name"
                  ref=""
                  :options="optionSiteGroup"
                ></b-form-select>
                <div
                  class="text-danger"
                  v-if="$v.assignment.customer_id.$error"
                >
                  Tolong isi site group
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="mb-4">
                <h6 class="mb-1">
                  Site (Customer Branch) <span class="text-danger">*</span>
                </h6>
                <b-form-select
                  v-model="selectSite"
                  :options="optionSite"
                  value-field="id"
                  text-field="name"
                ></b-form-select>
                <div
                  class="text-danger"
                  v-if="$v.assignment.customer_branch_id.$error"
                >
                  Tolong isi site
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-4">
                <h6 class="mb-1">Shift <span class="text-danger">*</span></h6>
                <b-form-select
                  v-model="assignment.shift_id"
                  :options="optionShift"
                  value-field="id"
                  text-field="name"
                ></b-form-select>
                <div class="text-danger" v-if="$v.assignment.shift_id.$error">
                  Tolong isi shift
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-md-12">
              <div class="mb-4">
                <h6 class="mb-1">Divisi <span class="text-danger">*</span></h6>
                <b-form-select
                  v-model="assignment.divisi"
                  :options="optionDivisi"
                ></b-form-select>
              </div>
            </div>
          </div> -->
          <div class="row">
            <!-- <div class="col-md-6">
              <div class="mb-4">
                <h6 class="mb-1">
                  Application User <span class="text-danger">*</span>
                </h6>
                <b-form-select
                  v-model="assignment.applicationUser"
                  :options="optionAppUser"
                  value-field="id"
                  text-field="nama"
                ></b-form-select>
              </div>
            </div> -->
            <div class="col-md-6">
              <div class="mb-4">
                <h6 class="mb-1">Supervisor Name</h6>
                <b-form-select
                  v-model="assignment.supervisor_id"
                  :options="optionSupervisor"
                  value-field="id"
                  text-field="name"
                ></b-form-select>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">Jabatan <span class="text-danger">*</span></h6>
              <b-form-select
                v-model="assignment.role_id"
                :options="optionPosition"
                value-field="id"
                text-field="name"
              ></b-form-select>
              <div class="text-danger" v-if="$v.assignment.role_id.$error">
                Tolong isi jabatan
              </div>
            </div>
          </div>
        </b-card>
        <b-card header-tag="header" footer-tag="footer">
          <template v-slot:header>
            <h6 class="mb-0">Kontrak Kerja</h6>
          </template>
          <div class="row">
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">No Kontrak</h6>
              <b-form-input
                v-model="employmentContract.contract_number"
                placeholder="Masukan no kontrak anda"
              ></b-form-input>
            </div>
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">Status Kontrak</h6>
              <b-form-select
                v-model="employmentContract.contract_status"
                :options="optionStatusContract"
                value-field="id"
                text-field="name"
              ></b-form-select>
            </div>
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">Mulai Kontrak</h6>
              <b-form-input
                type="date"
                v-model="employmentContract.contract_start"
                placeholder="Masukan mulai kontrak anda"
              ></b-form-input>
            </div>
            <!-- <div class="col-md-6 mb-4">
              <h6 class="mb-1">
                Area Kerja <span class="text-danger">*</span>
              </h6>
              <b-form-select
                v-model="assignment.working_area"
                :options="optionWorkArea"
                value-field="id"
                text-field="name"
              ></b-form-select>
            </div> -->
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">Akhir Kontrak</h6>
              <b-form-input
                type="date"
                v-model="employmentContract.contract_end"
                placeholder="Masukan akhir kontrak anda"
              ></b-form-input>
            </div>
            <!-- <div class="col-md-6 mb-4">
              <h6 class="mb-1">Keluar/Resign</h6>
              <b-form-input
                type="date"
                v-model="employmentContract.out"
                placeholder="Masukan keluar/resign anda"
              ></b-form-input>
            </div> -->
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">Jenis KTA</h6>
              <b-form-input
                v-model="employmentContract.kta_type"
                placeholder="Masukan jenis kta anda"
              ></b-form-input>
            </div>
            <div class="col-md-6 mb-4">
              <h6 class="mb-1">No KTA</h6>
              <b-form-input
                v-model="employmentContract.kta_number"
                placeholder="Masukan no KTA anda"
              ></b-form-input>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <div class="row justify-content-center gutter-b">
      <div class="col-md-2">
        <b-button
          variant="primary"
          class="w-100"
          @click="handleSubmit"
          :disabled="isLoading"
          >Submit</b-button
        >
      </div>
    </div>
    <!--end:: Add Employee-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { required, email } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import mixin from "@/mixins/index";

export default {
  name: "Employee",
  mixins: [mixin],
  data() {
    return {
      // PROFILE EMPLOYEE
      previewUrl: null,
      employee: {
        nip: "",
        name: "",
        birth_place: "",
        dob: "",
        gender: "",
        blood_type: "",
        id_number: "",
        family_card_number: "",
        // nameBank: "",
        // noRek: "",
        npwp_number: "",
        religion: "",
        email: "",
        phone: "",
        address: "",
        marital_status: "",
        ethnic: "",
        education: "",
        photo: null,
        bank_account: {
          account_name: "",
          account_number: "",
          bank: {
            id: null,
            name: "",
          },
        },
      },
      optionBloodType: [
        {
          value: "",
          text: "Pilih golongan darah",
        },
        {
          value: "A",
          text: "A",
        },
        {
          value: "AB",
          text: "AB",
        },
        {
          value: "B",
          text: "B",
        },
        {
          value: "O",
          text: "O",
        },
      ],
      optionBank: [
        { value: "", text: "Pilih Bank" },
        { value: "mandiri", text: "Bank Mandiri" },
        { value: "bca", text: "BCA" },
      ],
      optionReligion: [
        { value: "", text: "Pilih Agama" },
        { value: "islam", text: "Islam" },
        { value: "protestan", text: "Protestan" },
        { value: "katolik", text: "Katolik" },
        { value: "hindu", text: "Hindu" },
        { value: "budha", text: "Budha" },
        { value: "konghucu", text: "Kong Hu Cu" },
      ],
      // SITE GROUP CUSTOMER
      assignment: {
        customer_id: null,
        customer_branch_id: null,
        shift_id: null,
        // divisi: null,
        // applicationUser: null,
        supervisor_id: null,
        role_id: null,
      },
      optionSiteGroup: [{ id: null, name: "Pilih Site Group" }],
      optionSite: [{ id: null, name: "Pilih Site" }],
      optionShift: [{ id: null, name: "Pilih shift" }],
      optionDivisi: [
        { value: null, text: "Pilih divisi" },
        { value: "marketing", text: "Marketing" },
        { value: "operational", text: "Operational" },
      ],
      optionAppUser: [{ id: null, name: "Pilih application user" }],
      optionSupervisor: [{ id: null, name: "Pilih nama supervisor" }],
      // KONTRAK KERJA
      employmentContract: {
        contract_number: "",
        contract_start: "",
        // working_area: "",
        // out: "",
        kta_type: "",
        //
        contract_status: null,
        contract_end: "",
        kta_number: "",
      },
      optionWorkArea: [
        { id: "", name: "Pilih Area Kerja" },
        { id: "jakarta", name: "TDP Jakarta" },
        { id: "bandung", name: "TDP Bandung" },
      ],
      //
      optionStatusContract: [
        { id: null, name: "Pilih Status Contract" },
        { id: "pkwt", name: "PKWT" },
        { id: "pkwtt", name: "PKWTT" },
      ],
      optionPosition: [{ id: null, name: "Pilih Jabatan" }],
      isLoading: false,
    };
  },
  validations: {
    employee: {
      nip: {
        required,
      },
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
      },
    },
    assignment: {
      customer_id: {
        required,
      },
      customer_branch_id: {
        required,
      },
      shift_id: {
        required,
      },
      role_id: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    selectCustomer: {
      get() {
        return this.assignment.customer_id;
      },
      set(value) {
        this.assignment.customer_id = value;
        this.getCustomerBranchList(value);
      },
    },
    selectSite: {
      get() {
        return this.assignment.customer_branch_id;
      },
      set(value) {
        this.assignment.customer_branch_id = value;
        this.getSupervisor(value);
        this.getShift(value);
      },
    },
  },
  async mounted() {
    await this.getData();
    let lastBreadcrumb = {};
    if (this.$route.name === "employee-edit") {
      lastBreadcrumb = { title: "Edit Employee" };
      this.getDataEmployee();
    } else lastBreadcrumb = { title: "Add Employee" };
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Employee", route: "/employee" },
      lastBreadcrumb,
    ]);
  },
  methods: {
    handlePhoto(e) {
      const file = e.target.files[0];
      this.employee.photo = file;
      this.previewUrl = URL.createObjectURL(file);
    },
    async handleSubmit() {
      this.$v.employee.$touch();
      this.$v.assignment.$touch();
      if (!this.$v.employee.$invalid && !this.$v.assignment.$invalid) {
        this.isLoading = true;
        // eslint-disable-next-line
        const { bank_account, ...employee } = this.employee
        const form = {
          bank_account_name: this.employee.bank_account.account_name,
          bank_account_number: this.employee.bank_account.account_number,
          bank_id: this.employee.bank_account.bank.id,
          ...employee,
          ...this.assignment,
          ...this.employmentContract,
        };
        let formData = new FormData();
        for (const [key, value] of Object.entries(form)) {
          if (value) formData.append(key, value);
        }
        try {
          let message = "Successfully Add Employee";
          if (this.$route.name === "employee-edit") {
            const employeeID = this.$route.params.id;
            await this.$axios.put(
              `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/employee/detail/${employeeID}`,
              formData
            );
            message = "Successfully edit employee";
          } else {
            await this.$axios.post(
              `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/employee`,
              formData
            );
          }
          this.$bvToast.toast(message, {
            title: "Successs",
            variant: "success",
            solid: true,
          });
          this.$router.push({ path: `/employee/list` });
        } catch ({ response }) {
          this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        } finally {
          this.isLoading = false;
        }
      }
    },
    async getData() {
      try {
        const request = [
          this.$axios.get(
            `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/customers`,
            {
              params: {
                limit: 1000,
              },
            }
          ),
          this.$axios.get(
            `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/roles`
          ),
        ];
        const [customer, roles] = await Promise.all(request);
        this.getBankList();
        this.optionSiteGroup = [
          { id: null, name: "Pilih Site Group" },
          ...customer.data.list,
        ];
        this.optionPosition = [
          { id: null, name: "Pilih Jabatan" },
          ...roles.data,
        ];

        // if (!this.getTDPBranch) {
        //   this.selectCustomer = this.getCustomerId;
        //   await this.getCustomerBranchList(this.selectCustomer);
        //   this.selectSite = this.getCustomerBranchId;
        // }
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message || "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    async getBankList() {
      await this.$axios
        .get(
          `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/banks?query=&sort=created_at&order=desc&limit=150&offset=0`
        )
        .then(({ data }) => {
          const list = data.list.sort((a, b) => a.name.localeCompare(b.name));
          this.optionBank = [{ id: null, name: "Pilih Bank" }, ...list];
        });
    },
    async getCustomerBranchList(customer_id) {
      await this.$axios
        .get(
          `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/customer/${customer_id}/customer-branches`
        )
        .then(({ data }) => {
          this.optionSite = [{ id: null, name: "Pilih Site" }, ...data.list];
        });
    },
    getShift(value) {
      this.assignment.customer_branch_id = value;
      const shift = this.optionSite.find((item) => item.id === value);
      if (shift) {
        this.optionShift = [{ id: null, name: "Pilih Shift" }, ...shift.shifts];
      }
    },
    async getSupervisor(value) {
      const supervisor = await this.$axios.get(
        `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/customer-branch/${value}/supervisors`
      );
      this.optionSupervisor = [
        { id: null, name: "Tidak Ada" },
        ...supervisor.data.list,
      ];
    },
    async getDataEmployee() {
      const employee = this.$route.params.id;
      try {
        await this.$axios
          .get(
            `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/employee/detail/${employee}`,
            {
              params: this.params,
            }
          )
          .then(async ({ data }) => {
            const {
              nip,
              name,
              birth_place,
              dob,
              gender,
              blood_type,
              id_number,
              family_card_number,
              bank_account,
              // nameBank,
              // noRek,
              npwp_number,
              religion,
              user_profile: { email },
              phone,
              address,
              marital_status,
              ethnic,
              education,
              photo,
              //
              customer_branch: { customer_id, id: customer_branch_id },

              shift: { id: shift_id },
              supervisor_id,

              // WORKING AREA
              contract_number,
              contract_start,
              kta_type,
              contract_status,
              contract_end,
              role: { id: role_id },
              kta_number,
            } = data;
            this.previewUrl = photo;
            this.employee = {
              nip,
              name,
              birth_place,
              dob,
              gender,
              blood_type,
              id_number,
              family_card_number,
              // nameBank,
              // noRek,
              npwp_number,
              religion: religion.toLowerCase(),
              email,
              phone,
              address,
              marital_status,
              ethnic,
              education,
              bank_account: {
                id: bank_account?.id || null,
                account_number: bank_account?.account_number || null,
                account_name: bank_account?.account_name || null,
                bank: {
                  id: bank_account?.bank?.id || null,
                  name: bank_account?.bank?.name || null,
                },
              },
            };
            this.assignment = {
              customer_id,
              customer_branch_id,
              shift_id,
              supervisor_id: supervisor_id || null,
              role_id,
              // divisi: null,
              // applicationUser: null,
              // supervisorName: null,
            };
            await this.getCustomerBranchList(customer_id);
            this.getShift(customer_branch_id);
            this.employmentContract = {
              contract_number,
              contract_start,
              kta_type,
              contract_status,
              contract_end,
              kta_number,
            };
          });
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
  },
};
</script>
